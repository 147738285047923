<template>
  <div class="header-main">
    <div class="d-flex justify-space-between align-center py-5 mb-3">
      <h3 class="page-title-list">
        招待券利用新規予約
        <!-- 顧客名 顧客ID{{$route.query.ticketId}} -->
      </h3>
      <div class="d-flex">
        <v-btn class="btn-close-custom" color="#C92D2D" href="javascript:window.open('','_self').close();">
          <v-icon size="20">mdi-close</v-icon>
          閉じる
        </v-btn>
        <v-btn
          class="btn-create-custom ml-4"
          color="#1873D9"
          @click="handleCreate"
          :disabled="!checkPerUser"
          >予約作成</v-btn
        >
      </div>
    </div>
    <v-form ref="form">
      <v-row class="bg--white py-6 px-8">
        <!-- =============== Read only =============== -->
        <v-col cols="12" md="5">
          <v-card class="py-3 px-4 bg--secondary">
            <v-btn
              class="btn-back-to-calendar mb-8"
              color="#1873D9"
              :disabled="!checkPerUser"
              @click="showDialog()"
            >
              カレンダーから入力
            </v-btn>
            <label>利用契約</label>
            <span class="text-content">招待券利用新規予約</span>

            <div class="d-flex justify-space-between mt-8">
              <div class="pr-2 w-50">
                <label>施設を選択</label>
                <v-select
                  :disabled="!checkPerUser"
                  @change="selectFac($event)"
                  :items="arrListRoomType"
                  item-value="facilityId"
                  item-text="facilityName"
                  v-model="facilityId"
                  class="text-input"
                ></v-select>
              </div>
              <div class="pl-2 w-50">
                <label>部屋タイプ</label>
                <v-select
                  @change="selectRoomType"
                  :disabled="!checkPerUser"
                  v-model="roomTypeId"
                  :items="roomTypeListSelect"
                  item-value="roomTypeId"
                  item-text="roomTypeName"
                  :rules="rules.require"
                  class="text-input"
                ></v-select>
              </div>
            </div>

            <div class="mt-8 d-flex align-start">
              <v-icon class="mt-6" color="#333333">mdi-calendar-month</v-icon>
              <div class="ml-2">
                <label class="d-block mb-1">チェックイン</label>
                <v-menu
                  transition="scale-transition"
                  offset-y min-width="auto"
                  :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <div class="custom-btn-picker">
                      <v-btn
                        :disabled="!checkPerUser"
                        outlined
                        v-on="on"
                        small
                        class="bg-white--btn text-checkin-date"
                        >{{ formatDate(finishData.checkInDate) }}</v-btn
                      >
                    </div>
                  </template>
                  <v-date-picker
                    :min="availableFromDate"
                    :max="availableToDate"
                    v-model="finishData.checkInDate"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label
                class="mt-6 mx-1"
                style="color: #000000; font-size: 16px; font-weight: 400"
                >〜</label
              >
              <v-icon class="mt-6" color="#333333">mdi-calendar-month</v-icon>
              <div class="mr-2">
                <label class="mb-1">チェックアウト</label>
                <div class="text-checkout mt-3 ml-2">
                  {{ formatDate(checkOutDate) }}
                </div>
              </div>
              <span class="text-days mt-6 mb-n1 ml-2">{{ 1 + "泊" }}</span>
            </div>

            <div class="d-flex mt-8">
              <v-row>
                <v-col :cols="2">
                  <label>大人</label>
                  <v-select
                    :disabled="!checkPerUser"
                    v-model="finishData.numberOfAdults"
                    :items="numberOfAdults"
                    :rules="rules.numberOfAdults"
                    class="text-input"
                  >
                  </v-select>
                </v-col>
                <v-col :cols="2">
                  <label>子供(非添い寝)</label>
                  <v-select
                    :disabled="!checkPerUser"
                    v-model="finishData.numberOfChildren"
                    :items="numberOfCustomer"
                    :rules="rules.numberOfChildren"
                    class="text-input"
                  >
                  </v-select>
                </v-col>
                <v-col :cols="2">
                  <label>子供(添い寝)</label>
                  <v-select
                    :disabled="!checkPerUser"
                    v-model="finishData.numberOfChildrenWithBedShare"
                    :items="numberOfCustomer"
                    :rules="rules.numberOfChildrenWithBedShare"
                    class="text-input"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" offset="0" offset-md="1">
          <div class="w-30">
            <label>予約バーに表示させる備考内容</label>
            <v-text-field
              :disabled="!checkPerUser"
              v-model="finishData.bookingBarNote"
              class="text-field pt-0"
               :rules="[$rules.checkLenghInput(255)]"
            ></v-text-field>
          </div>

          <label>宿泊代表者</label>
          <v-text-field
            :disabled="!checkPerUser"
            v-model="finishData.representativeName"
            class="text-field pt-0"
             :rules="[$rules.checkLenghInput(255)]"
          ></v-text-field>

          <div class="disabled-box">
            <label>宿泊代表者ヨミガナ</label>
            <v-text-field
              :disabled="!checkPerUser"
              v-model="finishData.representativeKana"
              class="text-field pt-0"
              :rules="[$rules.checkKatakana, $rules.checkLenghInput(255)]"
            ></v-text-field>

            <label>電話番号</label>
            <!-- <v-text-field
              :disabled="!checkPerUser"
              v-model="finishData.representativeTel"
              class="text-field pt-0"
              :rules="[$rules.checkNumber, $rules.checkLenghInput(15)]"
            ></v-text-field> -->
            <v-text-field
              :disabled="!checkPerUser"
              v-model="finishData.representativeTel"
              class="text-field pt-0"
            ></v-text-field>

            <label>メールアドレス</label>
            <v-text-field
              :disabled="!checkPerUser"
              v-model="finishData.representativeEmail"
              class="text-field pt-0"
              :rules="[$rules.isEmailValid]"
            ></v-text-field>

            <label>国を選択</label>
            <v-select
              :disabled="!checkPerUser"
              class="text-select pt-0"
              :items="itemsData.representativeCountry"
              v-model="finishData.representativeCountry"
              :rules="[$rules.checkLenghInput(255)]"
            ></v-select>

            <label>郵便番号</label>
            <v-text-field
              :disabled="!checkPerUser"
              class="text-field pt-0"
              v-model="finishData.representativePostalCode"
              :rules="[$rules.postalCodeNoHyphen]"
            ></v-text-field>

            <label>住所</label>
            <v-text-field
              :disabled="!checkPerUser"
              v-model="finishData.representativeAddress1"
              class="text-field pt-0"
              :rules="[$rules.checkLenghInput(255)]"
            ></v-text-field>
          </div>
        </v-col>
        <!-- ========================================== -->
      </v-row>
    </v-form>
    <ReservationCalendarDialog
      v-if="isShowFrameSelection"
      :visible="isShowFrameSelection"
      :dataCreateCoupon="dataCreateCoupon"
      @setData="setData"
      @close="isShowFrameSelection = false"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { checkNumber, isEmailValid } from '@/utils/validators'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import ReservationCalendarDialog from '@/views/dashboard/reservation/reservationCalendarBlock/ReservationCalendarPageDialog.vue'
import { BOOKING_TICKET_TYPE } from '@/api/graphql/invitation-ticket/invitation-ticket'
import { handlError } from '@/constants/functions'
import gql from 'graphql-tag'
import { isoDate } from '@/utils/dateUtil'

export default {
  name: 'TicketBookingCreate',
  components: { ReservationCalendarDialog },
  data () {
    return {
      arrListRoomType: [],
      roomTypeListSelect: [],
      isCreateCounpon: true,
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      rules: {
        require: [(v) => !!v || this.$t('rules.isRequired')],
        number: [
          (v) => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          (v) =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32')
        ],
        numberOfAdults: [
          (v) => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          (v) =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),
          (v) =>
            (v &&
              v +
                this.finishData.numberOfChildren <=
                this.maxOccupancy) ||
            !v ||
            this.$t('rules.createBookingTooManyGuests')
        ],
        numberOfChildren: [
          (v) => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          (v) =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32'),
          (v) =>
            (v &&
              v +
                this.finishData.numberOfAdults <=
                this.maxOccupancy) ||
            !v ||
            this.$t('rules.createBookingTooManyGuests')
        ],
        numberOfChildrenWithBedShare: [
          (v) => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          (v) =>
            (v && v <= 2147483647) || !v || this.$t('rules.maximumNumberInt32')
        ],
        text: [
          (v) =>
            (v && v.length <= 255) ||
            !v ||
            this.$t('rules.maximumNCharacter', { value: 255 })
        ],
        email: [
          (v) => !v || isEmailValid(v) || this.$t('rules.emailIsInvalid'),
          (v) =>
            (v && v.length <= 255) ||
            !v ||
            this.$t('rules.maximumNCharacter', { value: 255 })
        ],
        postalCode: [
          (v) => (v && checkNumber(v)) || !v || this.$t('rules.numberInvalid'),
          (v) =>
            (v && v.length === 7) ||
            (v && v.length === undefined) ||
            !v ||
            this.$t('rules.includeNCharacters', { value: 7 })
        ]
      },
      itemsData: {
        facilities: [],
        roomTypes: [],
        representativeCountry: ['日本', 'その他']
      },
      availableFromDate: null,
      availableToDate: null,
      facilityId: null, // Int
      roomTypeId: null, // Int
      finishData: {
        // Require
        days: [
          {
            inventoryTypeId: 1 // static
          }
        ],
        bookingTypeId: 4, // Int
        roomTypeId: null, // Int
        ticketId: this.$route.query.ticketId, // String
        // Left input
        checkInDate: moment().format('YYYY-MM-DD'), // String
        numberOfAdults: 1, // Int
        numberOfChildren: 0, // Int
        numberOfChildrenWithBedShare: 0, // Int

        // Right input
        bookingBarNote: null, // String
        representativeName: null, // String
        representativeKana: '', // String
        representativeTel: '', // String
        representativeEmail: '', // String
        representativeCountry: null, // String
        representativePostalCode: '', // String
        representativeAddress1: '' // String
      },
      isShowFrameSelection: false
    }
  },
  computed: {
    ...mapGetters(['getSelectDay']),
    getFacilityName () {
      if (this.facilityId) {
        const facility = this.itemsData.facilities.find(
          (facility) => facility.id === this.facilityId
        )
        return facility ? facility.name : ''
      }

      return ''
    },
    getRoomTypeName () {
      if (this.finishData.roomTypeId) {
        const facility = this.itemsData.roomTypes.find(
          (facility) => facility.id === this.finishData.roomTypeId
        )
        return facility ? facility.name : ''
      }

      return ''
    },
    maxOccupancy () {
      return 10
    },
    numberOfCustomer () {
      return [...Array(this.maxOccupancy + 1).keys()]
    },
    numberOfAdults () {
      return this.numberOfCustomer.filter(val => val > 0)
    },
    checkOutDate () {
      return moment(this.finishData.checkInDate).add(1, 'days').format('YYYY-MM-DD')
    }
  },
  mounted () {
    this.getBookingTicketType()
    this.actionGetBookingTicketType(this.$route.query.ticketNo).then(
      (bookingTicketType) => {
        const facilities = []
        const roomTypes = []

        if (bookingTicketType.roomTypes) {
          bookingTicketType.roomTypes.forEach((item, idx) => {
            facilities.push({
              id: item.roomType.facility.id,
              name: item.roomType.facility.name
            })
            roomTypes.push({
              id: item.roomType.id,
              name: item.roomType.name
            })
          })

          this.itemsData.facilities = facilities
          this.itemsData.roomTypes = roomTypes
        }

        if (bookingTicketType.availableFromDate) {
          this.availableFromDate = bookingTicketType.availableFromDate
        }

        if (bookingTicketType.availableToDate) {
          this.availableToDate = bookingTicketType.availableToDate
        }
      }
    )
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title =
          '招待券利用新規予約顧客名 顧客ID' + this.$route.query.ticketId ||
          '招待券利用新規予約'
      }
    },

    async getSelectDay () {
      const selectDay = Object.values(this.getSelectDay)[0]
      this.finishData.days = [{
        inventoryTypeId: selectDay.inventoryTypeId
      }]
      this.facilityId = await this.arrListRoomType.find(item => item.roomTypeId === selectDay.roomTypeId).facilityId
      this.selectFac(this.facilityId)
      this.roomTypeId = await selectDay.roomTypeId
      this.finishData.roomTypeId = this.roomTypeId
      this.finishData.checkInDate = selectDay.stayDate
    }
  },
  methods: {
    ...mapActions(['actionGetBookingTicketType', 'actionCreateBookingV4']),
    ...mapMutations(['setAlertSuccess', 'setAlertError']),
    async handleCreate () {
      if (this.$refs.form.validate()) {
        if (this.finishData.representativePostalCode === '') {
          this.finishData.representativePostalCode = null
        }
        const dataCreate = { ...this.finishData }

        const finalize = async () => {
          const { id } = await this.actionCreateBookingV4(dataCreate)
          this.$goToRoute.booking.replace(id)
        }
        if (dataCreate.checkInDate < isoDate(new Date())) {
          // if checkInDate is in the past, show confirm dialog TO2020-937
          this.$confirm({
            message: '過去の日付で予約を作成します。よろしいですか？',
            ok: finalize
          })
        } else {
          await finalize()
        }
      } else {
        this.setAlertError(this.$t('messages.newCreationFailed'))
      }
    },

    async getBookingTicketType () {
      const variables = {
        id: this.$route.query.ticketNo
      }
      await this.$apollo
        .query({
          query: gql`${BOOKING_TICKET_TYPE}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.availableFromDate = data.data.bookingTicketType.availableFromDate
          this.finishData.checkInDate = this.availableFromDate
          this.availableToDate = data.data.bookingTicketType.availableToDate
          this.arrListRoomType = []
          for (
            let i = 0;
            i < data.data.bookingTicketType.roomTypes.length;
            i++
          ) {
            var obj = {
              facilityId:
                data.data.bookingTicketType.roomTypes[i].roomType.facility.id,
              facilityName:
                data.data.bookingTicketType.roomTypes[i].roomType.facility.name,
              roomTypeId: data.data.bookingTicketType.roomTypes[i].roomType.id,
              roomTypeName:
                data.data.bookingTicketType.roomTypes[i].roomType.name
            }
            this.arrListRoomType.push(obj)
          }
          this.roomTypeListSelect = this.arrListRoomType
          this.facilityId = this.arrListRoomType[0].facilityId
          this.selectFac(this.facilityId)
        })
        .catch((error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_TICKET_TYPE, variables, error.graphQLErrors))
          handlError(this.$store.commit, error.graphQLErrors)
        })
    },

    showDialog () {
      const data = {
        isCreateCounponTicket: this.isCreateCounpon,
        facilityId: this.facilityId,
        facilityName: this.getFacilityName,
        roomTypeId: this.roomTypeId,
        arrListRoomType: this.arrListRoomType,
        fromDate: this.availableFromDate,
        toDate: this.availableToDate
      }
      this.dataCreateCoupon = data
      this.isShowFrameSelection = true
    },

    formatDate (value) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      if (moment(value) > moment(this.dateTo)) {
        this.dateTo = value
      }
      return moment(value).format('yyyy年M月D日 (' + d + ')')
    },

    setData (data) {
      this.finishData.checkInDate = data[0].date
    },

    selectFac (event) {
      this.roomTypeId = null
      this.roomTypeListSelect = []
      for (let i = 0; i < this.arrListRoomType.length; i++) {
        if (event === this.arrListRoomType[i].facilityId) {
          this.roomTypeListSelect.push(this.arrListRoomType[i])
        }
      }
    },

    selectRoomType (event) {
      this.finishData.roomTypeId = event
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title-list {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.text-input {
  ::v-deep {
    .v-select__selection--comma {
      font-weight: 500;
      font-size: 14px;
      color: #444444;
    }
  }
}
.w-50 {
  width: 50%;
}
.w-30 {
  width: 30%;
}
label {
  font-size: 10px;
  display: block;
  color: #000000;
  font-weight: 600;
}
.text-field {
  ::v-deep {
    .v-text-field__slot {
      input {
        font-weight: 500 !important;
        font-size: 14px !important;
        color: #444444;
      }
    }
  }
}
.text-select {
  ::v-deep {
    .v-select__selection--comma {
      font-weight: 500 !important;
      font-size: 14px !important;
      color: #444444;
    }
  }
}
.btn-close-custom {
  color: #ffffff;
}
.btn-create-custom {
  color: #ffffff;
}
.btn-back-to-calendar {
  color: #ffffff;
}
.text-days {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.text-checkin-date {
  ::v-deep {
    .v-btn__content {
      font-size: 10px;
      color: #000000;
      font-weight: 500;
    }
  }
}
.text-checkout {
  font-size: 10px;
  color: #000000;
  font-weight: 500;
}
</style>
